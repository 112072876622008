import '../../Styles/Team.css';
import ann from '../../Assets/Staff/ann.jpeg';
import { useState } from 'react';
import StaffPopup from '../StaffPopup';

function Ann() {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
        document.getElementById('ann').scrollTo(0, 0);
    }

    return (
        <section className='staff-profile'>
            <div className='staff-card' onClick={handleClick}>
                <div className='staff-picture'>
                    <img src={ann} alt='ann' />
                </div>
                <div className='staff-info'>
                    <div className='staff-name'>
                        Ann
                    </div>
                    <div className='staff-learn-more'>
                        Learn More
                    </div>
                </div>
            </div>
            <StaffPopup
                open={open}
                handleClick={handleClick}
                title='Ann - Dental Hygenist'
                name='ann'
                subname = ''
                image={ann}
                blurb={<div className='staff-bio-blurb'>
                    I am a second generation Arizonian.
                    Lived here when Phoenix was a small farming community.
                    I attended Central High School and had a fun time cruising
                    central phoenix. After high school I attended Phoenix College
                    and the dental hygiene school. I graduated in the first class
                    and we had to promote ourselves to dentists as they were not
                    aware of dental hygiene as a profession. My first job was in general
                    dentistry although my interests were in Periodontics. I eventually
                    ended up in Periodontics for 28 years and was certified in expanded
                    functions. I later switched my direction to general dentistry and
                    Prosthodontics which was a new learning experience. As the doc
                    I was working for passed away from ALS, I became a new member of
                    Dr. Yoza’s Staff. Always the best for last! I am semi-retired now
                    and still enjoy my old friends and patients who I adore. Although
                    athletics were fun for years, I now enjoy my cabin and fishing
                    in Alaska with my partner in crime Dan. My 2 kids are grown and
                    have 1 granddaughter and a cat.
                </div>}
            />


        </section>

    )
}

export default Ann