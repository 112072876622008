import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import '../Styles/ServicePopup.css';

function ServicePopup({open, handleClick, name, id, image, blurb}) {

    


    return (
        <div className={open === id ? 'service-info' : 'service-hidden'}>
            <div className='service-display' id={id}>
                <div className='service-info-x' onClick={() => handleClick('')}>
                    <FontAwesomeIcon className='service-icon' icon={faTimes}/>
                </div>
                <div className='service-info-intro'>
                    {name}
                    <div className='service-info-underline'></div>
                </div>
                
                <div className='service-info-picture'>
                    <img src={image} alt={name} />
                </div>

                {blurb}
            </div>
            
        </div>
    )
}

export default ServicePopup