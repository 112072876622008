// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFunctions } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCEkKLk00NpO8hk1pnK_qOHHpO9RYrTKlQ",
  authDomain: "uhfd-87d29.firebaseapp.com",
  projectId: "uhfd-87d29",
  storageBucket: "uhfd-87d29.firebasestorage.app",
  messagingSenderId: "725624630539",
  appId: "1:725624630539:web:f880d4b3672b8bd3a1460e",
  measurementId: "G-40QTP42QHN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
const analytics = getAnalytics(app);

export {functions, analytics};