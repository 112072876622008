import '../../Styles/Team.css';
import kirsten from '../../Assets/Staff/kirsten.jpeg';
import { useState } from 'react';
import StaffPopup from '../StaffPopup';

function Kirsten() {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
        document.getElementById('kirsten').scrollTo(0, 0);
    }


    return (
        <section className='staff-profile'>
            <div className='staff-card' onClick={handleClick}>
                <div className='staff-picture'>
                    <img src={kirsten} alt='kirsten' />
                </div>
                <div className='staff-info'>
                    <div className='staff-name'>
                        Kirsten
                    </div>
                    <div className='staff-learn-more'>
                        Learn More
                    </div>
                </div>
            </div>
            <StaffPopup
                open={open}
                handleClick={handleClick}
                title='Kirsten - Dental Hygenist'
                name='kirsten'
                subname = ''
                image={kirsten}
                blurb={<div className='staff-bio-blurb'>
                    Originally from Alaska, Kirsten moved to Arizona in July 2010.
                    She graduated from dental hygiene school in Mesa, Arizona and has
                    over 5 years of dental experience. In her spare time, Kirsten likes
                    to check out new restaurants, play frolf, go geocaching, and traveling.
                </div>}
            />
        </section>
    )
}

export default Kirsten