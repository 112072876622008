import '../../Styles/Team.css';
import jamie from '../../Assets/Staff/jamie.jpeg'
import { useState } from 'react';
import StaffPopup from '../StaffPopup';

function Jamie() {
    const [open, setOpen] = useState(false);


    const handleClick = () => {
        setOpen(!open);
        document.getElementById('jamie').scrollTo(0, 0);
    }


    return (
        <section className='staff-profile'>  
            <div className='staff-card'  onClick={handleClick}>
                <div className='staff-picture'>
                    <img src={jamie} alt='jamie' />
                </div>
                <div className='staff-info'>
                    <div className='staff-name'>
                        Jamie
                    </div>
                    <div className='staff-learn-more'>
                        Learn More
                    </div>
                </div>
            
            </div>
            <StaffPopup
                open={open}
                handleClick={handleClick}
                title='Jamie - Assistant'
                name='jamie'
                subname = ''
                image={jamie}
                blurb={
                    <div className='staff-bio-blurb'>
                        <div>
                            Jamie was born in California, grew up in Las Vegas, Nevada.
                            After moving to Arizona she passionately began working in the
                            dental field in 2005. Over the years her experience has vastly
                            grown with working in Pediatrics, Oral Surgery, Periodontal and General Dentistry.
                            “It is like art to me and I love helping transform the life of someone else.”
                            Here at UHFD you will see what true team work looks like. We take pride in making
                            our patients feel comfortable and respected.
                        </div>
                        <div>
                            Outside the office she is involved with church activities and functions.
                            Jamie and her husband David are also a part of Heavens Healing Hands,
                            a non profit organization that is Restoring Dignity to Veterans,Transforming
                            Homelesssness, Bridging Services, and Creating Hope. Having a big, generous
                            servants heart, Jamie loves to love on people. “I am just a vessel spreading
                            love like Jesus!” She loves spending time with her family, friends and especially
                            her grandson making them laugh and smile warms her heart. She loves Crocs, Groot,
                            Olaf and Giraffes. Jamie enjoys anything outdoors which include her annual camping
                            trips with family, being on the water with her kayak or paddleboard, getting in a good
                            4am workout, hiking, traveling and going on cruises.
                        </div>
                    </div>
                }
            />
        </section>
    )
}

export default Jamie