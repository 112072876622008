import '../Styles/App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import Services from './Services';
import Office from './Office';
import Staff from './Staff';
import Forms from './Forms';
import Request from './Request';
import Disclaimer from './Disclaimer';
import PatientPrivacy from './PatientPrivacy';

function App() {
    return (
        <div className='App-Container'>
            <div className='App'>
                <Router>
                    <div>
                        <Routes>
                            <Route exact path='/' element={< Home />}></Route>
                            <Route exact path='/office' element={< Office />}></Route>
                            <Route exact path='/services' element={< Services />}></Route>
                            <Route exact path='/staff' element={< Staff />}></Route>
                            <Route exact path='/forms' element={< Forms />}></Route>
                            <Route exact path='/request' element={< Request />}></Route>
                            <Route exact path='/disclaimer' element={< Disclaimer />}></Route>
                            <Route exact path='/patientprivacy' element={< PatientPrivacy />}></Route>
                        </Routes>
                    </div>
                </Router>                
            </div>
        </div>
        
    );
}

export default App;
