import '../Styles/OverlayBlock.css';
import { Link } from 'react-router-dom';

function OverlayBlock(props) {
  return (
    <section className='overview-section'>
      <Link id='block-container' to={props.link}>
        <div id='block-image-container'>
            <img src={props.picture} alt={props.alt} />
        </div>
        <div id='block-content'>
            {props.content}
        </div>
      </Link>
    </section>
    
  )
}

export default OverlayBlock