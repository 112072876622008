import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import '../Styles/ServicePopup.css';
import { useEffect } from 'react';

function StaffPopup({open, handleClick, title, name, subname, image, blurb}) {

    useEffect(() => {
        if (open) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

    }, [open]);
    
    const handleScroll = (event) => {
        event.stopPropagation();
    };

    return (
        <div className={open ? 'staff-bio' : 'staff-hidden'}  onClick={e => e.stopPropagation()} onWheel={handleScroll}>
            <div className='staff-display' id={name}>
                <div className='staff-bio-x' onClick={handleClick}>
                    <FontAwesomeIcon className='staff-bio-icon' icon={faTimes}/>
                </div>
                <div className='staff-bio-intro'>
                    {title}
                    <div className='staff-bio-underline'></div>
                    {subname}
                </div>
                
                <div className='staff-bio-picture'>
                    <img src={image} alt={name} />
                </div>

                {blurb}
            </div>
            
        </div>
    )
}

export default StaffPopup