import '../Styles/Staff.css';
import React, { useEffect } from 'react';
import teamnarrow from '../Assets/team-narrow.jpg';
import Header from './Header';
import Footer from './Footer';
import Dentist from './Team/Dentist';
import Blanca from './Team/Blanca';
import Angie from './Team/Angie';
import Kirsten from './Team/Kirsten';
import Ann from './Team/Ann';
import Jamie from './Team/Jamie';
import Heather from './Team/Heather';
import Kat from './Team/Kat';


function Staff() {



    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div className='staff-container'>
            <Header active='staff'/>
            <img className='staff-image' src={teamnarrow} alt='team' />
            <div className='staff-title'>
                Meet Our Team
            </div>
            <div className='staff-content'>
                <Dentist />
                <Blanca />
                <Angie />
                <Kirsten />
                <Ann />
                <Jamie />
                <Heather />
                <Kat />
            </div>
            <Footer />
        </div>
    )
}

export default Staff