import '../../Styles/Team.css';
import heather from '../../Assets/Staff/heather.jpeg';
import { useState } from 'react';
import StaffPopup from '../StaffPopup';

function Heather() {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
        document.getElementById('heather').scrollTo(0, 0);
    }


    return (
        <section className='staff-profile'>

        
            <div className='staff-card' onClick={handleClick}>
                <div className='staff-picture'>
                    <img src={heather} alt='heather' />
                </div>
                <div className='staff-info'>
                    <div className='staff-name'>
                        Heather
                    </div>
                    <div className='staff-learn-more'>
                        Learn More
                    </div>
                </div>
            
            </div>
            <StaffPopup
                open={open}
                handleClick={handleClick}
                title='Heather - Front Office'
                name='heather'
                subname = ''
                image={heather}
                blurb={<div className='staff-bio-blurb'>
                    Front office
                </div>}
            />
        </section>
    )
}

export default Heather