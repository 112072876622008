import '../Styles/SiteOverview.css';
import OverlayBlock from './OverlayBlock';
import logo from '../Assets/uhfd-logo.svg';
import wave from '../Assets/wave.jpg';



function SiteOverview(props) {
  return (
    <main className='site-overview'>
        {
            props.isMobile ? 
                <section className='overview-section'>
                    <div id='block-welcome'>
                        <div id='block-image-container'>
                            <img src={wave} alt='union hills family dentistry logo' />
                        </div>
                        <div id='block-center'>
                            <div id='mobile-welcome-text'>
                                {props.content['welcome']}
                            </div>
                        </div>
                    </div>
                </section>

            :
                <section className='overview-section-summary'>
                    <div className='overview-side'>
                        <img id='overview-logo' src={logo} alt='union hills family dentistry logo' />
                    </div>
                    <div className='overview-side'>
                        <div className='welcome-text'>
                            {props.content['welcome']}
                        </div>
                    </div>
                </section>

        }
        
          
        <OverlayBlock
            picture={props.images['services']}
            alt='our services'
            link='/services'
            content={
                <div className='overview-header'>
                    {props.content['services']}
                </div>
            }
        />

        <OverlayBlock
            picture={props.images['about']}
            alt='our office'
            link='/office'
            content={
                <div className='overview-header'>
                    {props.content['about']}
                </div>
        }
        />


        <OverlayBlock
            picture={props.images['team']}
            alt='our team'
            link='/staff'
            content={
                <div className='overview-header'>
                    {props.content['team']}
                </div>
            }
        />
        


        <OverlayBlock
            picture={props.images['forms']}
            alt='forms'
            link='/forms'
            content={
                <div className='overview-header'>
                    {props.content['forms']}
                </div>
            }
        />
            

    </main>

  )
}

export default SiteOverview