import '../../Styles/Team.css';
import kat from '../../Assets/Staff/kat.jpeg';
import { useState } from 'react';
import StaffPopup from '../StaffPopup';

function Kat() {
    const [open, setOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
        document.getElementById('kat').scrollTo(0, 0);
    }

    return (
        <section className='staff-profile'>
            <div className='staff-card' onClick={handleClick}>
                <div className='staff-picture'>
                    <img src={kat} alt='kat' />
                </div>
                <div className='staff-info'>
                    <div className='staff-name'>
                        Kat
                    </div>
                    <div className='staff-learn-more'>
                        Learn More
                    </div>
                </div>
            </div>
            <StaffPopup
                open={open}
                handleClick={handleClick}
                title='Kat - Assistant'
                name='kat'
                subname = ''
                image={kat}
                blurb={<div className='staff-bio-blurb'>
                    Assistant
                </div>}
            />
        </section>
    )
}

export default Kat