import '../Styles/Services.css';
import { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import office from '../Assets/office.jpeg';
import CosmeticPorcelainVeneersImg from '../Assets/ServiceImages/Veneer.jpeg';
import TeethWhiteningImg from '../Assets/ServiceImages/WhiteningImg.jpeg';
import ImplantRestorationsImg from '../Assets/ServiceImages/ImplantImg.jpeg';
import TiredOfDenturesThatDontStayInImg from '../Assets/ServiceImages/DenturesComplete.jpeg';
import InvisibleBracesImg from '../Assets/ServiceImages/InvisibleBracesImg.jpeg';
import SameDayCrownImg from '../Assets/ServiceImages/Crown-Icon.jpeg';
import ServicePopup from './ServicePopup';

import VeneersImg from '../Assets/ServiceImages/Veneer-Procedure.jpeg';
import CrownImg from '../Assets/ServiceImages/Crown.jpeg';
import DenturesImg from '../Assets/ServiceImages/Dentures.jpeg';


function Services() {

    const [open, setOpen] = useState('');


    const handleClick = (value) => {
        if (open) {
            document.getElementById(open).scrollTo(0, 0);
        }
        setOpen(value);
        
    }


    return (
        <div className='services-container'>
            <Header active='services' />
            <div className='services-title'>
                <div className='services-overlay-container'>
                    <div className='services-overlay-image'>
                        <img src={office} alt='office' />
                    </div>
                    <div className='services-title-text'>
                        Our services
                    </div>
                </div>
            </div>
            <div className='services-content'>
                <div className='services-item'>
                    <div className='services-item-inner-container' onClick={() => handleClick('veneers')}>
                        <div className='services-item-image-container'>
                            <img src={CosmeticPorcelainVeneersImg} alt='Cosmetic Porcelain Veneers' />
                        </div>   
                        <div className='services-item-overlay'>
                            <div className='services-item-name'>
                                Cosmetic Porcelain Veneers
                            </div>
                        </div>
                    </div>
                    <ServicePopup
                        open={open}
                        handleClick={handleClick}
                        name='Cosmetic Porcelain Veneers'
                        id = 'veneers'
                        image={VeneersImg}
                        blurb={
                            <div className='service-blurb'>
                                <div>
                                    Porcelain veneers are thin, custom-made shells that are bonded to the front surface of your
                                    teeth. Made from high-quality dental porcelain, these veneers are designed to mimic the
                                    appearance of natural teeth, providing a beautiful, natural-looking smile.
                                </div>
                                <h2>Benefits</h2>
                                <div>
                                    One of the key advantages of porcelain veneers is their ability to address a variety of cosmetic
                                    concerns. Whether you have discolored, chipped, misaligned, or irregularly shaped teeth,
                                    porcelain veneers can effectively camouflage these imperfections, creating a harmonious and
                                    aesthetically pleasing smile.
                                </div>
                                <div>
                                    Our dental office takes a personalized approach to porcelain veneers. During your consultation,
                                    our experienced dental professionals will carefully evaluate your oral health, discuss your
                                    cosmetic goals, and design a treatment plan tailored to your unique needs. We utilize advanced
                                    digital imaging technology to create a precise model of your teeth, ensuring that your porcelain
                                    veneers are custom-crafted for a perfect fit and a natural-looking result.
                                </div>
                                <h2>How It Is Done</h2>
                                <div>
                                    The placement of porcelain veneers is a minimally invasive process. After preparing your
                                    teeth by removing a small amount of enamel, we will securely bond the veneers to the tooth surface.
                                    This process not only enhances the appearance of your smile but also provides
                                    durability and long-lasting results.
                                </div>
                                <div>
                                    With porcelain veneers, you can enjoy a radiant smile that boosts your self-confidence and leaves a
                                    lasting impression. Contact our dental office today to schedule a consultation and discover how our
                                    porcelain veneers can transform your smile and help you achieve the stunning, flawless
                                    teeth you've always desired.
                                </div>
                            </div>
                        }
                        />
                </div>
                <div className='services-item'>
                    <div className='services-item-inner-container' onClick={() => handleClick('whitening')}>
                        <div className='services-item-image-container'>
                            <img src={TeethWhiteningImg} alt='Teeth Whitening' />
                        </div>   
                        <div className='services-item-overlay'>
                            <div className='services-item-name'>
                                Teeth Whitening
                            </div>
                        </div>
                    </div>
                    <ServicePopup
                        open={open}
                        handleClick={handleClick}
                        name='Teeth Whitening'
                        id = 'whitening'
                        image={TeethWhiteningImg}
                        blurb={
                            <div className='service-blurb'>
                                <div>
                                    Teeth whitening is a safe and effective cosmetic dental procedure that
                                    effectively removes stains and discoloration from the surface of your teeth.
                                    Over time, teeth can become stained due to factors such as aging, consumption
                                    of certain foods and beverages, tobacco use, and poor oral hygiene. Our teeth
                                    whitening treatments are designed to reverse these effects, revealing a
                                    dazzling smile.
                                </div>
                                <div>
                                    Professional teeth whitening can brighten your smile by several shades, enhancing your
                                    overall appearance and boosting your self-confidence. Whether you choose in-office whitening,
                                    where we apply a powerful whitening gel and activate it with a special light, or opt for custom
                                    take-home whitening kits, our treatments are designed to deliver noticeable and long-lasting results.
                                </div>
                                <h2>
                                    Interested?
                                </h2>
                                <div>
                                    Experience the transformative benefits of teeth whitening at our dental office. Schedule a consultation
                                    with our dedicated team to discuss your goals and determine the most appropriate treatment plan for you.
                                    Unleash the radiance of your smile and regain the confidence to show off your pearly whites with our professional
                                    teeth whitening services.
                                </div>
                            </div>
                        }
                        />
                </div>
                <div className='services-item'>
                    <div className='services-item-inner-container' onClick={() => handleClick('implant')}>
                        <div className='services-item-image-container'>
                            <img src={ImplantRestorationsImg} alt='Implant Restorations' />
                        </div>   
                        <div className='services-item-overlay'>
                            <div className='services-item-name'>
                                Implant Restorations
                            </div>
                        </div>
                    </div>
                    <ServicePopup
                        open={open}
                        handleClick={handleClick}
                        name='Implant Restorations'
                        id='implant'
                        image={ImplantRestorationsImg}
                        blurb={
                            <div className='service-blurb'>
                                <div>
                                    Dental implants, made of biocompatible titanium, are surgically placed
                                    into the jawbone to provide a stable foundation. Custom-made restorations,
                                    such as crowns, bridges, or dentures, are then attached to the implants to
                                    create a natural-looking smile.
                                </div>
                                <h2>
                                    Benefits
                                </h2>
                                <div>
                                    Implant restorations offer a multitude of benefits. Firstly, they provide aesthetic
                                    improvements, as the restorations are meticulously designed to match the shape,
                                    color, and size of your natural teeth, resulting in a seamless and attractive smile.
                                    Secondly, implant restorations offer enhanced functionality. The implants fuse with the
                                    jawbone, providing a stable and durable base for chewing, speaking, and biting, similar
                                    to natural teeth. Unlike traditional removable dentures or bridges, implant restorations
                                    are fixed in place, eliminating concerns about discomfort, slipping, or shifting.
                                </div>
                                <div>
                                    Moreover, implant restorations contribute to the long-term health of your jawbone. When a tooth is
                                    lost, the underlying bone can deteriorate over time. Dental implants act as artificial tooth roots,
                                    stimulating the bone and preventing bone loss. This not only preserves the structure and integrity of
                                    the jaw but also promotes a youthful facial appearance. At our dental office, our experienced team will
                                    assess your oral health, develop a personalized treatment plan, and utilize advanced technology for
                                    precise implant placement and expertly crafted restorations.
                                </div>
                            </div>
                        }
                        />
                </div>
                <div className='services-item'>
                    <div className='services-item-inner-container' onClick={() => handleClick('invisible-braces')}>
                        <div className='services-item-image-container'>
                            <img src={InvisibleBracesImg} alt='Invisible Braces' />
                        </div>   
                        <div className='services-item-overlay'>
                            <div className='services-item-name'>
                                Invisible Braces
                            </div>
                        </div>
                    </div>
                    <ServicePopup
                        open={open}
                        handleClick={handleClick}
                        name='Invisible Braces'
                        id='invisible-braces'
                        image={InvisibleBracesImg}
                        blurb={
                            <div className='service-blurb'>
                                <div>
                                    Invisible braces are a revolutionary alternative to traditional metal braces. They are custom-made,
                                    transparent aligners that gradually and gently shift your teeth into the desired position. The aligners
                                    are virtually invisible when worn, allowing you to undergo orthodontic treatment without drawing unnecessary attention.
                                </div>
                                <h2>
                                    Benefits
                                </h2>
                                <div>
                                    One of the key advantages of invisible braces is their comfort and convenience. The aligners are made of smooth,
                                    BPA-free plastic, eliminating the discomfort associated with metal brackets and wires. Additionally, they can be easily
                                    removed for eating, brushing, and flossing, allowing for optimal oral hygiene and unrestricted enjoyment of your favorite foods.
                                </div>
                                <div>
                                    Throughout your treatment, you will progress through a series of aligners, wearing each set for a designated period of
                                    time before moving on to the next. Regular check-ups with our dental professionals will ensure that your treatment is
                                    progressing as planned and allow for any necessary adjustments.
                                </div>
                            </div>
                        }
                        />
                </div>
                <div className='services-item'>
                    <div className='services-item-inner-container' onClick={() => handleClick('crowns')}>
                        <div className='services-item-image-container'>
                            <img src={SameDayCrownImg} alt='Same Day Crowns' />
                        </div>   
                        <div className='services-item-overlay'>
                            <div className='services-item-name'>
                                Same Day Crowns
                            </div>
                        </div>
                    </div>
                    <ServicePopup
                        open={open}
                        handleClick={handleClick}
                        name='Same Day Crowns'
                        id='crowns'
                        image={CrownImg}
                        blurb={
                            <div className='service-blurb'>
                                <div>
                                    Crowns are synthetic caps, usually made of porcelain, placed on top of the tooth. 
                                    Crowns are used to restore a tooth’s function and appearance following a procedure 
                                    such as a root canal. Crowns also restore teeth that have extensive decay, large 
                                    defective fillings or teeth that have cracks. 
                                </div>
                                <div>
                                    In our office we have the ability to fabricate porcelain crowns in a short 2 hour 
                                    appointment utilizing CEREC technology. 
                                </div>
                                <h2>How Does The CEREC Machine Work?</h2>
                                <div>
                                    The CEREC (Chairside Economical Restoration of Esthetic Ceramics) machine is a dental
                                    tool used to make customized ceramic dental restorations in one visit, speeding up a
                                    process that typically requires sending a mold of a patient’s teeth to a dental
                                    laboratory to get a part made, which can take up to a week and requires multiple
                                    trips to the dentist.
                                </div>
                                <div>
                                    CEREC machines produce ceramic onlays, inlays and dental crowns with the use of
                                    3D scans, allowing dentists to provide same-day restorations for patients
                                    who need crowns or dental implants.
                                </div>
                                <div>
                                    CEREC machines use 3D-imaging software to make a detailed replication of your
                                    teeth. The process begins with your dentist taking X-rays and photographs
                                    of your mouth with the CEREC machine, which uses the information received to
                                    create a 3D model of your mouth. The Computer-Aided Design (CAD) software
                                    then highlights the parts of the model that need to be fixed.
                                </div>
                                <div>
                                    Once the 3D model and the areas that need to be restored have been highlighted,
                                    the machine will create the restoration from a solid ceramic block. The
                                    machine-created piece will then be color-matched to your natural teeth.
                                </div>
                                <h2>Advantages of CEREC machines</h2>
                                <div>
                                    Saves time: More dentists are having CEREC machines installed in their offices
                                    because it saves both them and their patients time — for example, if you go to
                                    a dentist’s office to get a dental crown and the dentist does not have a CEREC
                                    machine, they will have to take a mold of your teeth and send it to a laboratory
                                    to get the crown made, and then the dentist will give you a temporary crown until
                                    you come back in when the permanent crown arrives to finish the procedure;
                                    basically, having a CEREC machine eliminates the waiting period so you do not
                                    have to go back a second time.
                                </div>
                                <div>
                                    Accuracy: The CAD technology used by CEREC machines provides the most accurate
                                    replication of your tooth, which means the size and shape of the replica will
                                    be almost identical to your real tooth.
                                </div>
                                <div>
                                    Reduces waste: Due to the accuracy of the machine, the replica created by the
                                    CEREC machine is very precise, which means it only uses the needed amount of
                                    the material used to create the replica, reducing waste
                                </div>
                                <h2>
                                    Caring For Your Crowns
                                </h2>
                                <div>
                                    With proper care, a good quality crown could last
                                    up to eight years or longer. It is very important
                                    to floss in the area of the crown to avoid excess plaque
                                    or collection of debris around the restoration.
                                </div>
                                <div>
                                    Certain behaviors such as jaw clenching or
                                    bruxism (teeth grinding) significantly shorten the life of a
                                    crown. Moreover, eating brittle foods, ice or hard candy can
                                    compromise the adhesion of the crown, or even damage the crown.
                                </div>
                            </div>
                        }
                        />
                </div>
                <div className='services-item'>
                    <div className='services-item-inner-container' onClick={() => handleClick('dentures')}>
                        <div className='services-item-image-container'>
                            <img src={TiredOfDenturesThatDontStayInImg} alt="Tired of Dentures that don't stay in?" />
                        </div>   
                        <div className='services-item-overlay'>
                            <div className='services-item-name'>
                                Tired of Dentures that don't stay in?
                            </div>
                        </div>
                    </div>
                </div>
                <ServicePopup
                        open={open}
                        handleClick={handleClick}
                        name='Dentures'
                        id='dentures'
                        image={DenturesImg}
                        blurb={
                            <div className='service-blurb'>
                                <div>
                                    We have a solution for you!
                                    All-on-4 dentures offer a transformative dental restoration
                                    that combines the benefits of dental implants and fixed dentures.
                                    By strategically placing four to six dental implants in your jawbone, we
                                    secure a custom-designed denture that looks, feels, and functions just
                                    like natural teeth.
                                </div>
                                <h2>
                                    Benefits
                                </h2>
                                <div>
                                    One major advantage of All-on-4 dentures is their immediate functionality.
                                    In many cases, we can place the implants and attach the denture on the same day,
                                    providing you with a fully functional set of teeth. This immediate loading approach
                                    allows you to eat, speak, and smile confidently right from the start.
                                </div>
                                <div>
                                    We understand that each patient is unique, and we take a personalized approach to your treatment plan.
                                    Our experienced dental professionals will evaluate your oral health, jawbone structure, and specific goals to determine
                                    if All-on-4 dentures are the best fit for you. If needed, we can discuss alternative treatment options that align with your
                                    individual needs.
                                </div>
                                <h2>
                                    Interested?
                                </h2>
                                <div>
                                    Union Hills Family Dentistry is committed to delivering exceptional care in a
                                    comfortable environment. We utilize state-of-the-art facilities and compassionate
                                    staff to ensure your dental health is our top priority throughout the entire treatment
                                    process.
                                </div>
                                <div>
                                    Ready to regain your smile and enjoy the benefits of All-on-4 dentures?
                                    Contact us today to schedule a consultation and take the first step towards a
                                    confident and beautiful smile once again.
                                </div>
                            </div>
                        }
                        />
            </div>
            <Footer />
        </div>
    )
}

export default Services