import '../../Styles/Team.css';
import angie from '../../Assets/Staff/angie.jpeg';
import { useState } from 'react';
import StaffPopup from '../StaffPopup';

function Angie() {
    const [open, setOpen] = useState(false);


    const handleClick = () => {
        setOpen(!open);
        document.getElementById('angie').scrollTo(0, 0);
    }


    return (
        <section className='staff-profile'>
            <div className='staff-card' onClick={handleClick}>
                <div className='staff-picture'>
                    <img src={angie} alt='angie' />
                </div>
                <div className='staff-info'>
                    <div className='staff-name'>
                        Angie
                    </div>
                    <div className='staff-learn-more'>
                        Learn More
                    </div>
                </div>
            </div>
            <StaffPopup
                open={open}
                handleClick={handleClick}
                title='Angie - Dental Hygenist'
                name='angie'
                subname = ''
                image={angie}
                blurb={<div className='staff-bio-blurb'>
                    Angela Espinoza is excited to return to the Union Hills Family Dentistry
                    team as a Dental Hygienist! She graduated from Rio Salado Community
                    College Dental Hygiene Program in 2021. Outside of work, Angela is
                    happily married and has four adopted cats. She enjoys all art and
                    crafts including making greeting cards, sewing, painting and reading.
                    Angela loves to travel and experience new cultures and food!
                </div>}
            />
        </section>
    )
}

export default Angie