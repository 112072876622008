/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import '../Styles/Request.css';
import Header from './Header';
import Footer from './Footer';
import {ThreeDots} from 'react-loader-spinner';
import { useState, useRef } from 'react';
import { functions } from "../firebaseConfig"; // Import Firebase functions
import { httpsCallable } from "firebase/functions";

function Request() {

    const daysOfWeek = ['No Preference','Monday', 'Tuesday', 'Wednesday', 'Thursday'];

    const [firstNameCorrect, setFirstNameCorrect] = useState(true);
    const [lastNameCorrect, setLastNameCorrect] = useState(true);
    const [emailCorrect, setEmailCorrect] = useState(true);
    const [phoneCorrect, setPhoneCorrect] = useState(true);
    
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [timeOfDay, setTimeOfDay] = useState('');
    const [selectedDays, setSelectedDays] = useState([]);
    const [reason, setReason] = useState('');
    const isClicked = useRef(false);
    const [buttonText, setButtonText] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [consent, setConsent] = useState(false);
    


    useEffect(() => {
        window.scrollTo(0, 0);  
    }, [submitted]);

    

    

    const toggleDay = (day) => {
        let updatedSelection = selectedDays.includes(day)
            ? selectedDays.filter(d => d !== day) // Remove if already selected
            : [...selectedDays, day]; // Add if not selected
        setSelectedDays(updatedSelection);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (isClicked.current) {
            return;
        }

        isClicked.current = true;
        setButtonText(false);

        const incorrect = [];

        if (!firstName) {
            setFirstNameCorrect(false);
            incorrect.push('First Name');
        }

        if (!lastName) {
            setLastNameCorrect(false);
            incorrect.push('Last Name');
        }

        if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            setEmailCorrect(false);
            incorrect.push('Email');
        }

        if (!phone || !/^(\+?\d{1,4}[\s.-]?)?(\(?\d{2,4}\)?[\s.-]?)?\d{3,4}[\s.-]?\d{3,4}$/.test(phone)) {
            setPhoneCorrect(false);
            incorrect.push('Phone Number');
        }

        if (incorrect.length > 0) {
            alert(`Please ensure that the following fields are filled correctly: \n ${incorrect.join(', ')}`);
            isClicked.current = false;
            setButtonText(true);
            return;
        }

        setFirstNameCorrect(true);
        setLastNameCorrect(true);
        setEmailCorrect(true);
        setPhoneCorrect(true);
        

        try {

            const info = {
                firstName: firstName,
                lastName: lastName,
                email: email,
                phone: phone,
                dayOfWeek: selectedDays.join(', '),
                timeOfDay: timeOfDay,
                reason: reason,
                consent: consent
            };


            const sendEmailFunction = httpsCallable(functions, "sendEmail");
            const response = await sendEmailFunction(info);

            isClicked.current = false;
            setButtonText(true);
           

            console.log(response.data.message);

        } catch (err) {
            console.log(err);
            alert('Submit not successful. Please try again later or call at (623)878-4460 to schedule an appoinment.');
            setButtonText(true);
            isClicked.current = false;
            return;
        }

        setSubmitted(true);
    }

    const handleDisplay = () => {
        if(submitted) {
            return (
                <div className='request-container' >
                    <Header active='request' />
                    <div className='request'>
                        <div className='request-title'>
                            Request an appointment
                        </div>
                        <div className='request-description'>
                            Your request was succesfully submitted
                        </div>
                        <div className='request-another'>
                            <button onClick={() => setSubmitted(false)}>Submit Another Request</button>
                        </div>
                        <div className='request-notice'>
                            NOTICE: This is only a request. It is not a booked appointment.
                            Our scheduling coordinator will contact you to confirm your appointment or
                            please call at (623)878-4460.
                        </div>
                    </div>
                    <Footer />
                </div >
            )
        }
        return (
            <div className='request-container' >
                <Header active='request' />
                <div className='request'>
                    <div className='request-title'>
                        Request an appointment
                    </div>
                    <div className='request-description'>
                        To request appointment availability, please fill out the form below.
                        Our scheduling coordinator will contact
                        you to confirm your appointment.
                    </div>
                    <div className='request-form'>
                        <div className='request-form-section'>
                            <div className='request-header'>
                                First Name <span>(required)</span>
                            </div>
                            <div className='request-name-inputs'>
                                <input className={firstNameCorrect ?  'request-required-input' : 'request-error-input'} type="text" id="firstname" value={firstName}
                                    onChange={(event) => setFirstName(event.target.value)}></input>
                            </div>
                            <div className='request-header'>
                                Last Name <span>(required)</span>
                            </div>
                            <div className='request-name-inputs'>
                                <input className={lastNameCorrect ?  'request-required-input' : 'request-error-input'} type="text" id="lastname" value={lastName}
                                    onChange={(event) => setLastName(event.target.value)}></input>
                            </div>
                            <div className='request-header'>
                                Email <span>(required)</span>
                            </div>
                            <div className='request-contact-inputs'>
                                <input className={emailCorrect ?  'request-required-input' : 'request-error-input'} type="text" id="email" value={email}
                                    onChange={(event) => setEmail(event.target.value)}></input>
                            </div>
                            <div className='request-header'>
                                Phone Number <span>(required)</span>
                            </div>
                            <div className='request-contact-inputs'>
                                <input className={phoneCorrect ?  'request-required-input' : 'request-error-input'} type="text" id="phone" value={phone}
                                    onChange={(event) => setPhone(event.target.value)}></input>
                            </div>
                        </div>
                        <div className='request-form-section'>
                            <div className='request-header'>
                                Preferred Time of the Day
                            </div>
                            <div>
                                <select id='timeofday' name='timeofday' onChange={(event) => setTimeOfDay(event.target.value)}>
                                    <option value=''>Select Time</option>
                                    <option value='Any'>No Preference</option>
                                    <option value='Morning'>Morning</option>
                                    <option value='Afternoon'>Afternoon</option>
                                </select>
                            </div>
                        </div>
                        <div className='request-form-section'>
                            <div className='request-header'>
                                Preferred Day of the Week
                            </div>
                            <div className='request-dayofweek'>
                                {daysOfWeek.map((day) => (
                                    <label key={day} onClick={() => toggleDay(day)}>
                                        <div className={selectedDays.includes(day) ? 'request-checkbox-checked' : 'request-checkbox'}></div>
                                        <span>{day}</span>
                                    </label>
                                ))}
                            </div>
                        </div>
                       
                        <div className='request-form-section'>
                            <div className='request-header'>
                                Reason for appointment:
                            </div>
                            <div className='request-reason-inputs'>
                                <textarea name='reason' id='reason' rows={5} value={reason}
                                    onChange={(event) => setReason(event.target.value)}></textarea>
                            </div>
                        </div>
                        <div className='request-form-section'>
                            <div className='request-consent'>
                                <label onClick={() => setConsent(!consent)}>
                                    <div className={consent ? 'request-checkbox-checked' : 'request-checkbox'}></div>
                                    <span>I agree to receive email correspondence</span>
                                </label>
                            </div>
                        </div>
                        
                        <div className='request-button-container'>
                            <button onClick={handleSubmit}>
                                {buttonText ? 
                                    'Submit' 
                                    : 
                                    <ThreeDots
                                        color='#FFFFFF'
                                        height={10}
                                        width={100}
                                    />
                                }
                            </button>
                        </div>
                        <div className='request-notice'>
                            NOTICE: This is only a request. It is not a booked appointment.
                            Our scheduling coordinator will contact you to confirm your appointment or
                            please call at (623) 878-4460.
                        </div>
                    </div>
                </div>
                <Footer />
            </div >
        )
    }


    return handleDisplay();
}

export default Request